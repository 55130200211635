/** auth map */
export default {
  /** 首推纸质合同归档 */
  contract_filing: 'declare_legal_file',
  /** 首推电子合同归档 */
  contract_filing_digital: 'declare_legal_file_electronic',
  /** 包装信息管理-编辑 */
  product_warehouse_data_edit: 'product_warehouse_data_edit',
  // 商品列表导出
  PRODUCT_DOWNLOAD: 'basic_product_export',
  // 商品基本单位维护
  PRODUCT_UNIT_MANAGER: 'product_unit_manager',
  /** 供应商首营管理 */
  SUPPLIER_INITIAL_QUALIFICATION: 'supplier_first_camp_manage',
  /** 供应商首营管理-首营通过 */
  SUPPLIER_INITIAL_QUAL_PASS: 'supplier_first_camp_pass',
  /** 供应商首营管理-首营待审核 */
  SUPPLIER_INITIAL_QUAL_PENDING: 'supplier_first_camp_pending_review',
  /** 供应商首营管理-首营驳回 */
  SUPPLIER_INITIAL_QUAL_REJECT: 'supplier_first_camp_reject',
  /** 供应商首营管理-待质量负责人审核 */
  SUPPLIER_INITIAL_QUAL_AUDIT: 'supplier_quality_director_pending_review',
  /** 供应商首营管理-更新待审核 */
  SUPPLIER_INITIAL_QUAL_UPDATE_AUDIT: 'supplier_modify_pending_reveiw',
  /** 供应商首营管理-返利供应商 */
  SUPPLIER_INITIAL_QUAL_REBATE: 'supplier_rebate',
  /** 供应商首营管理-已解绑 */
  SUPPLIER_INITIAL_QUAL_UNBOUND: 'supplier_first_camp_unbound',
  /** 供应商资料配置 */
  SUPPLIER_INFO_CONFIGURATION: 'supplier_info_configuration',
  /** 供应商首营资料管理-首营通过-上传收货证照 */
  SUPPLIER_INITIAL_QUAL_RECEIPT_CERT: 'supplier_upload_receiving_cert',
  /** 商品首营管理 */
  PRODUCT_INITIAL_QUAL_MANAGE: 'erp_goods_first_camp_manage',
  /** 商品首营管理-首营通过 */
  PRODUCT_INITIAL_QUAL_PASS: 'erp_goods_first_camp_pass',
  /** 商品首营管理-更新待审核 */
  PRODUCT_INITIAL_QUAL_PENDING_REVIEW: 'erp_goods_update_pending_reveiw',
  /** 商品首营管理-首营驳回 */
  PRODUCT_INITIAL_QUAL_UNREVIEWED: 'erp_goods_not_first_camp',
  /** 首营商品管理-导出 */
  PRODUCT_INITIAL_QUAL_DOWNLOAD: 'erp_goods_first_camp_export',

  /** 客户首营管理 */
  CUSTOMER_INITIAL_QUALIFICATION: 'customer_provider_audit',
  /** 客户首营管理-首营通过 */
  CUSTOMER_PROVIDER_PASS: 'customer_provider_pass',
  /** 客户首营管理-停售/停售恢复 */
  CUSTOMER_PROVIDER_PASS_STOP_SALE: 'customer_provider_pass_stop_sale',
  /** 客户首营管理-冻结/冻结恢复 */
  CUSTOMER_PROVIDER_PASS_FROZEN: 'customer_provider_pass_frozen',
  /** 客户首营管理-首营通过-导出 */
  CUSTOMER_PROVIDER_PASS_EXPORT: 'customer_provider_pass_export',
  /** 客户首营管理-首营通过-编辑 */
  CUSTOMER_PROVIDER_PASS_EDIT: 'customer_provider_pass_edit',

  /** 客户首营管理-首营待审核 */
  CUSTOMER_PROVIDER_AUDITING: 'customer_provider_auditing',
  /** 客户首营管理-首营待审核-审核 */
  CUSTOMER_PROVIDER_AUDITING_AUDIT: 'customer_provider_auditing_audit',
  /** 客户首营管理-首营待审核-导出 */
  CUSTOMER_PROVIDER_AUDIT_EXPORT: 'customer_provider_auditing_export',

  /** 客户首营管理-首营驳回 */
  CUSTOMER_PROVIDER_REJECT: 'customer_provider_reject',
  /** 客户首营管理-首营驳回-提交首营审批 */
  CUSTOMER_PROVIDER_REJECT_SUBMIT: 'customer_provider_reject_submit',
  /** 客户首营管理-首营驳回-导出 */
  CUSTOMER_PROVIDER_REJECT_EXPORT: 'customer_provider_reject_export',

  /** 客户变更审批 */
  CUSTOMER_CHANGE_APPROVAL: 'customer_update_provider_audit',
  /** 客户变更审批-变更待审批 */
  CUSTOMER_UPDATE_PROVIDER_AUDITING: 'customer_update_provider_auditing',
  /** 客户变更审批-变更待审批-审批 */
  CUSTOMER_UPDATE_PROVIDER_AUDITING_AUDIT: 'customer_update_provider_auditing_audit',
  /** 客户变更审批-变更待审批-导出 */
  CUSTOMER_UPDATE_PROVIDER_AUDITING_EXPORT: 'customer_update_provider_auditing_export',

  /** 客户资料配置 */
  CUSTOMER_INFO_CONFIGURATION: 'customer_provider_setting',
  /** 客户首营自动审核 */
  CUSTOMER_FIRST_CAMP_AUTO_REVIEW: 'customer_first_camp_auto_review',
  /** 客户首营自动审核-编辑 */
  CUSTOMER_FIRST_CAMP_AUTO_REVIEW_EDIT: 'customer_first_camp_auto_review_edit',
  /** 客户首营管理-编辑标签 */
  CUSTOMER_LABEL_EDIT: 'customer_label_edit',

  /** 客户发票类型维护 */
  CUSTOMER_INVOICE_TYPE_MAINTENANCE: 'customer_invoice_type_maintain',
  /** 客户发票类型维护-导入/编辑 */
  CUSTOMER_INVOICE_TYPE_MAINTAIN_UPDATE: 'customer_invoice_type_maintain_update',

  /** 召回药品管理 */
  PRODUCT_RECALL_MANAGEMENT: 'product_recall',
  /** 召回药品信息维护 */
  PRODUCT_RECALL_EDIT: 'product_recall_edit',
  /** 添加召回药品 */
  PRODUCT_RECALL_ADD: 'product_recall_edit_add',
  /** 管理召回药品 */
  PRODUCT_RECALL_MODIFY: 'product_recall_edit_modify',
  /** 召回药品编码查询 */
  PRODUCT_RECALL_QUERY: 'product_recall_query',

  /** 商品首营审核 - 税务信息待审核 */
  PRODUCT_PROVIDER_TAX_INFO_PENDING: 'product_provider_audit_tax_info_wait',
  /** 商品首营审核 - 税务信息待审核 - 审核 */
  PRODUCT_PROVIDER_TAX_INFO_PENDING_AUDIT: 'product_provider_audit_tax_info_wait_audit',
  /** 商品首营审核 - 税务信息驳回 */
  PRODUCT_PROVIDER_TAX_INFO_REJECTED: 'product_provider_audit_tax_info_reject',
  /** 商品首营审核 - 税务信息驳回 - 审核 */
  PRODUCT_PROVIDER_TAX_INFO_REJECTED_AUDIT: 'product_provider_audit_tax_info_reject_audit',
  /** 商品资料管理 - 税务信息维护 */
  PROVIDER_TAX_INFO_MAINTAIN: 'provider_tax_info_maintain',
  /** 公司税率待审核-审核 */
  PROVIDER_PRODUCT_SPECIAL_TAX_WAIT_AUDIT: 'product_special_tax_provider_wait_audit_button',
  /** 税率待审核-审核 */
  PRODUCT_SPECIAL_TAX_WATI_AUDIT: 'product_special_tax_wait_audit_button',

  /** 商品资料管理 - 列表 */
  BASIC_PRODUCT_LIST: 'basic_product_list',
  /** 商品资料管理-商品证照类型编辑 */
  PRODUCT_CERT_TYPE_EDIT: 'basic_product_cert_edit',

  /** 首推近效期管控-编辑处理方式 */
  FIRST_EXPIRATION_CONTROL_UPDATE_METHOD: 'first_prod_expire_manage_edit_method',
  /** 首推近效期管控- 批量更新处理方式*/
  FIRST_EXPIRATION_CONTROL_BATCH_UPDATE_MTHOD: 'first_prod_expire_manage_update_method',
  /** 商品资料配置 */
  /** 电子监管配置 */
  ELECTRONIC_SUPERVISION: 'elecsupervision_config'
}
