const _import = require('@/libs/util.import.' + process.env.NODE_ENV)
const profile = [
  {
    path: 'special-drugs',
    name: 'SpecialDrugs',
    meta: {
      title: '特殊药品维护',
      auth: true
    },
    component: _import('data-center/product-config/special-drugs/index.vue')
  },
  {
    path: '/product-unit-manager',
    name: 'ProductUnitManager',
    meta: {
      title: '商品单位维护',
      auth: true
    },
    component: _import('data-center/product-config/product-unit-manager/index.vue')
  },
  {
    path: '/electronic-supervision',
    name: 'ElectronicSuperVision',
    meta: {
      title: '电子监管配置',
      auth: true,
      cache: true
    },
    component: _import('data-center/product-config/electronic-supervision/index.vue')
  }
]

export default profile
