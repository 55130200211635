// 菜单 侧边栏
import MODULES_CONFIGS from './auth-config'
export default [
  {
    path: '/index',
    title: '首页',
    icon: 'home',
    module: 'index',
    children: []
  },
  {
    path: '/index',
    title: '首页',
    icon: 'home',
    module: 'introducer_index',
    children: []
  },
  {
    path: '/index',
    title: '首页',
    icon: 'home',
    module: 'reviewer_index',
    children: []
  },
  {
    title: '首推引入人事务',
    icon: 'group',
    module: 'introducer_work_manage',
    children: [
      {
        path: '/introducer-dashboard',
        title: '引入人看板',
        module: 'introducer_work_manage_dashboard'
      },
      {
        path: '/introducer-work-manage-notice',
        title: '公告',
        module: 'introducer_work_manage_notice'
      },
      {
        path: '/introducer-work-manage-todo',
        title: '待办事项',
        module: 'introducer_work_manage_todo'
      }
    ]
  },
  {
    path: '/sales-statistics',
    title: '首推销售统计',
    icon: 'bar-chart',
    module: 'first_product_declare_sale_statistics',
    children: []
  },
  {
    title: '首推经营管理',
    icon: 'pie-chart',
    module: 'first_run_manage',
    children: [
      // {
      //   path: '/introducer-work-manage-product',
      //   title: '首推商品进程追踪',
      //   module: 'first_product_tracking'
      // },
      {
        path: '/purchase-record',
        title: '采购记录',
        module: 'purchasing_records'
      },
      // {
      //   path: '/group-agreement-receivable',
      //   title: '集团协议应收',
      //   module: 'first_product_group_agreement_receivable'
      // },
      // {
      //   path: '/first-replenish-task',
      //   title: '首推补货任务',
      //   module: 'first_replenishment_task'
      // },
      {
        path: '/first-replenish-guide',
        title: '首推补货指引',
        module: 'first_replenishment_guidelines'
      },
      {
        path: '/buy_and_give',
        title: '买赠活动发起',
        module: 'buy_and_give'
      },
      // {
      //   path: '/first-purchase-pending-info',
      //   title: '首次购进管理',
      //   module: 'first_batch_purchase_manage'
      // },
      {
        path: '/first-batch-manage',
        title: '首批购进管理',
        module: 'first_batch_purchase_manage'
      },
      // {
      //   path: '/first-gift-management',
      //   title: '首推赠品管理',
      //   module: 'first_gift_management'
      // },
      {
        path: 'first-expiration-control',
        title: '首推近效期管控',
        module: 'first_prod_expire_manage'
      }
    ]
  },
  {
    path: '/first-product-declare',
    title: '首推商品申报',
    icon: 'money',
    module: 'first_product_declare',
    children: []
  },
  {
    path: '/first-product-declare-management',
    title: '首推商品管理',
    icon: 'suitcase',
    module: 'first_product_declare_manager',
    children: []
  },
  {
    path: '/declare-contract-management',
    title: '首推合同管理',
    icon: 'table',
    module: 'first_product_declare_legal',
    children: []
  },
  {
    path: '/first-product-declare-review',
    title: '首推商品审核',
    icon: 'th-large',
    module: 'first_product_declare_review',
    children: []
  },
  {
    path: '/group-purchase-management',
    title: '厂牌品种资质',
    iconSvg: 'group-purchase',
    module: 'group_purchase_manage',
    children: []
  },
  {
    title: '客户资料',
    icon: 'address-card-o',
    module: 'customer_info',
    children: [
      {
        path: '/cuntomer-info-set',
        module: 'customer_create',
        title: '客户资料建档'
      },
      {
        path: '/stock-customer-set',
        module: 'customer_stock',
        title: '存量客户建档'
      },
      {
        path: '/customer-info-manage',
        module: 'customer_manage',
        title: '客户资料管理'
      },
      {
        path: '/customer-paper-report',
        module: 'customer_paper_test_manage',
        title: '纸质药检客户维护'
      },
      {
        path: '/customer/initial-qualification',
        module: MODULES_CONFIGS.CUSTOMER_INITIAL_QUALIFICATION,
        title: '客户首营管理'
      },
      {
        path: '/customer/profile-configuration',
        module: MODULES_CONFIGS.CUSTOMER_INFO_CONFIGURATION,
        title: '客户资料配置'
      },
      {
        path: '/customer/change-approval',
        module: MODULES_CONFIGS.CUSTOMER_CHANGE_APPROVAL,
        title: '客户变更审批'
      },
      {
        path: '/customer/invoice-type-maintenance',
        module: MODULES_CONFIGS.CUSTOMER_INVOICE_TYPE_MAINTENANCE,
        title: '客户发票类型维护'
      }
    ]
  },
  {
    title: '商品资料',
    icon: 'shopping-cart',
    module: 'product_info',
    children: [
      {
        path: '/first-product-info',
        module: 'first_product_list',
        title: '首推商品资料'
      },
      {
        path: '/product-info-manage',
        module: MODULES_CONFIGS.BASIC_PRODUCT_LIST,
        title: '商品资料管理'
      },
      {
        path: '/product-provider-audit',
        module: 'product_provider_audit',
        title: '商品首营审核'
      },
      {
        path: '/product/initial-qualification-manage',
        module: MODULES_CONFIGS.PRODUCT_INITIAL_QUAL_MANAGE,
        title: '商品首营管理'
      },
      {
        path: '/commodity-tax-rate-review',
        module: 'product_special_tax',
        title: '税务信息审核'
      },
      {
        path: '/stock-product-set',
        module: 'stock_product_manage',
        title: '存量商品查询'
      },
      {
        path: '/product-code-manager',
        module: 'product_code_maintain',
        title: '商品编码维护'
      },
      {
        path: '/product/packageinfos',
        module: 'product_warehouse_data_manager',
        title: '包装信息维护'
      }
    ]
  },
  {
    title: '供应商资料',
    icon: 'server',
    module: 'supplier_info',
    children: [
      {
        path: '/supplier-info-set',
        module: 'supplier_info_create',
        title: '供应商资料建档'
      },
      {
        path: '/supplier-info-manage',
        module: 'supplier_info_manage',
        title: '供应商资料管理'
      },
      {
        path: '/supplier/initial-qualification',
        module: MODULES_CONFIGS.SUPPLIER_INITIAL_QUALIFICATION,
        title: '供应商首营管理'
      },
      {
        path: '/supplier-info-config',
        module: MODULES_CONFIGS.SUPPLIER_INFO_CONFIGURATION,
        title: '供应商资料配置'
      }
    ]
  },
  {
    title: '召回药品管理',
    iconSvg: 'product-recall',
    module: MODULES_CONFIGS.PRODUCT_RECALL_MANAGEMENT,
    children: [
      {
        path: '/product-recall/maintain',
        module: MODULES_CONFIGS.PRODUCT_RECALL_EDIT,
        title: '召回药品信息维护'
      },
      {
        path: '/product-recall/search',
        module: MODULES_CONFIGS.PRODUCT_RECALL_QUERY,
        title: '召回药品编码查询'
      }
    ]
  },
  {
    title: '报表中心',
    icon: 'sun-o',
    module: 'statistic_report_center',
    children: [
      {
        path: '/review-statistic-report',
        title: '审核数统计报表',
        module: 'review_statistic_report'
      }
    ]
  },
  {
    path: '/introducer-visit-record',
    title: '商务拜访记录',
    icon: 'address-book-o',
    module: 'introducer_visit_log',
    children: []
  },
  {
    title: '商品资料配置',
    iconSvg: 'product-config',
    module: 'basic_product_setting',
    children: [
      {
        path: '/product-brand-manager',
        title: '商品品牌维护',
        module: 'product_brand_manager'
      },
      {
        path: '/common-name-manager',
        title: '商品通用名维护',
        module: 'product_common_name_manage'
      },
      {
        path: '/drug-factory-manager',
        title: '生产企业维护',
        module: 'drug_factory_manager'
      },
      {
        path: '/special-drugs',
        title: '特殊药品维护',
        module: 'special_product_maintain'
      },
      {
        path: '/product-unit-manager',
        title: '商品单位维护',
        module: MODULES_CONFIGS.PRODUCT_UNIT_MANAGER
      },
      {
        path: '/electronic-supervision',
        title: '电子监管配置',
        module: MODULES_CONFIGS.ELECTRONIC_SUPERVISION
      }
    ]
  },
  {
    title: '系统设置',
    icon: 'wrench',
    module: 'sys_manage',
    children: [
      {
        path: '/account-manage',
        title: '账号管理',
        module: 'account_manage'
      },
      {
        path: '/role-manage',
        title: '角色管理',
        module: 'role_manage'
      },
      {
        path: '/cashcow-buyer-management',
        title: '摇钱树采购员维护',
        module: 'cash_cow_buyer_manage'
      },
      {
        path: '/first-product-rules',
        title: '首推规则设置',
        module: 'primary_screen_rule_manage'
      },
      {
        path: '/first-product-tags',
        title: '首推商品设置',
        module: 'product_tag_manage'
      }
    ]
  }
]
